import logo from "./logo.svg";
import "./App.css";
import React from "react";

import CalendarTrans from "./components/Calendar";
import "semantic-ui-css/semantic.min.css";

function App() {
  return (
    <div className="App">
      {/* <header className="App-header"></header> */}
      <CalendarTrans />
    </div>
  );
}

export default App;
