import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import "./Calendar.scss";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import axios from "axios";
import { Icon } from "semantic-ui-react";
import moment from "moment";

const mainApiurl = "https://api.thefootballbrain.app/";

class CalendarTrans extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addEventDialogOpen: false,
      key: `calendar-${Date.now()}`,
      chosen_team: "0",
      selectedEvent: [],
      selectedEventModal: false,
      events: [],
    };
  }

  componentDidMount = () => {
    this.getBirthdaysMatchesTrainigs();
  };

  refreshEvents = () => {
    this.setState(
      {
        events: [],
      },
      () => {
        this.getBirthdaysMatchesTrainigs();
      }
    );
  };

  async getBirthdaysMatchesTrainigs() {
    const [matches, trainings] = await Promise.all([
      axios.get(
        `${mainApiurl}calendar/get_matches?id_club=${73}&id_team=${
          this.state.chosen_team
        }`
      ),
      axios.get(
        `${mainApiurl}calendar/get_trainings?id_club=${73}&id_team=${
          this.state.chosen_team
        }`
      ),
    ]);
    this.setState({
      events: this.state.events.concat(matches.data, trainings.data),
    });
  }

  renderEventContent(eventInfo) {
    return (
      <>
        <div>
          {eventInfo.event.extendedProps.description === "match" ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex" }}>
                <Icon name="futbol" style={{ color: "white" }} />
                <b style={{ textWrap: "balance" }}>
                  {"Meci : " +
                    `${moment(eventInfo.event.extendedProps.date_start).format(
                      "HH:mm"
                    )}`}
                </b>
              </div>
              <b style={{ textWrap: "wrap" }}>
                {"Locație: " + eventInfo.event.extendedProps.location ?? "-"}
              </b>
            </div>
          ) : (
            ""
          )}
          {eventInfo.event.extendedProps.description === "training" ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex" }}>
                <Icon
                  name="calendar alternate outline"
                  style={{ color: "white" }}
                />
                <b style={{ textWrap: "balance" }}>
                  {"Antrenament : " +
                    `${moment(eventInfo.event.extendedProps.date_start).format(
                      "HH:mm"
                    )} - ${moment(
                      eventInfo.event.extendedProps.date_end
                    ).format("HH:mm")}`}
                </b>
              </div>
              <b style={{ textWrap: "wrap" }}>
                {"Locație: " + eventInfo.event.extendedProps.location ?? "-"}
              </b>
            </div>
          ) : (
            ""
          )}
          {!["birthday", "match", "training"].includes(
            eventInfo.event.extendedProps.description
          ) ? (
            <div style={{ display: "flex" }}>
              <Icon name="calendar check outline" style={{ color: "white" }} />
              <b>{eventInfo.event.extendedProps.type}</b>
            </div>
          ) : (
            ""
          )}
          <div style={{ textWrap: "balance" }}>{eventInfo.event.title}</div>
        </div>
      </>
    );
  }

  eventClick = (eventClickInfo) => {
    const event = {
      id: eventClickInfo.event.id,
      name: eventClickInfo.event.title,
      date_start: moment(eventClickInfo.event.date_start).format("DD.MM.YYYY"),
      date_end: moment(eventClickInfo.event.date_end).format("DD.MM.YYYY"),
      event_added_description:
        eventClickInfo.event.extendedProps.event_added_description,
      location: eventClickInfo.event.extendedProps.location,
      description: eventClickInfo.event.extendedProps.description,
      hour_start: moment(eventClickInfo.event.extendedProps.date_start).format(
        "HH:mm"
      ),
      hour_end: moment(eventClickInfo.event.extendedProps.date_end).format(
        "HH:mm"
      ),
      full_date_start: eventClickInfo.event.extendedProps.date_start,
      full_date_end: eventClickInfo.event.extendedProps.date_end,
    };

    this.setState({ selectedEventModal: true, selectedEvent: event });
  };

  render() {
    return (
      <>
        <div className="card card-custom" style={{ padding: "15px" }}>
          <div id="calendar">
            <FullCalendar
              plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
              initialView="dayGridMonth"
              firstDay={1}
              key={this.state.key}
              locale={"ro"}
              eventContent={this.renderEventContent}
              headerToolbar={{
                start: "",
                center: "prev,title,next",
                end: "",
              }}
              buttonText={{
                today: "Astăzi",
                month: "Lună",
                day: "Zi",
                week: "Săptămână",
              }}
              scrollTime={false}
              events={this.state.events}
              contentHeight="auto"
              eventTimeFormat={{
                hour: "2-digit",
                minute: "2-digit",
              }}
            />
          </div>
        </div>
      </>
    );
  }
}

export default CalendarTrans;
